.tooltip {
  position: relative;
  display: inline-block; }

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 8px;
  padding: 5px;
  top: 30px;
  position: absolute;
  z-index: 10; }

.tooltip:hover .tooltiptext {
  visibility: visible; }

.container-base, .chart-container, .metrics-dashboard-container, .metrics-header-container, .debug-header, .debug-info-container {
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3)); }

.switcher > input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  marginLeft: 9px; }

.switcher > label {
  cursor: pointer;
  text-indent: -9999px;
  width: 41px;
  height: 25px;
  background: rgba(120, 120, 128, 0.16);
  display: block;
  border-radius: 100px;
  position: relative; }

.switcher > label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 23px;
  height: 23px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
  box-shadow: 0px 1.93548px 0.64516px rgba(0, 0, 0, 0.06), 0px 1.93548px 5.16129px rgba(0, 0, 0, 0.15); }

.switcher > input:checked + label {
  background: #00DADA; }

.switcher > input:disabled + label {
  background: #00DADA;
  opacity: 0.5; }

.switcher > input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%); }

.switcher > label:active:after {
  width: 25px; }

.checkbox {
  display: flex;
  align-items: center; }
  .checkbox > input[type="checkbox"] {
    -webkit-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 19px;
    width: 18px;
    border: 1px solid #E4EAF0;
    border-radius: 3.6px; }
  .checkbox > input[type="checkbox"]:checked {
    background: #B3F4F4;
    border: 1px solid #00DADA; }
  .checkbox > input[type="checkbox"]:hover {
    filter: brightness(90%); }
  .checkbox > input[type="checkbox"]:disabled {
    opacity: 0.6;
    pointer-events: none; }
  .checkbox > input[type="checkbox"]:after {
    -webkit-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    content: url(../imgs/check-mark-icon.svg);
    position: relative;
    left: 20%;
    top: 12%;
    opacity: 0; }
  .checkbox > input[type="checkbox"]:checked:after {
    opacity: 1; }
  .checkbox > input[type="checkbox"]:disabled:after {
    border-color: #7b7b7b; }
  .checkbox > label {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 10px;
    color: #212529;
    padding-right: 9px;
    text-transform: uppercase; }

::-webkit-scrollbar {
  width: 0px;
  height: 0px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px; }

a {
  text-decoration: none; }

body {
  margin: 0;
  background-color: #E5E5E5; }

.app-container {
  height: 100vh;
  width: 100%;
  display: flex; }

.onelight-transition {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.onelight-btn-primary, .onelight-btn-primary-unactive, .add-whats-new-btn, .publish-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px; }
  .onelight-btn-primary:hover, .onelight-btn-primary-unactive:hover, .add-whats-new-btn:hover, .publish-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-btn-primary:focus, .onelight-btn-primary-unactive:focus, .add-whats-new-btn:focus, .publish-btn:focus {
    outline: none; }
  .onelight-btn-primary:disabled, .onelight-btn-primary-unactive:disabled, .add-whats-new-btn:disabled, .publish-btn:disabled {
    opacity: 0.5; }
  .onelight-btn-primary-unactive {
    background-color: #E4EAF0; }

.onelight-btn-apply {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  color: white;
  font-size: 16px;
  line-height: 18px;
  width: 120px;
  height: 36px;
  padding: 0; }
  .onelight-btn-apply:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-btn-apply:focus {
    outline: none; }
  .onelight-btn-apply:disabled {
    opacity: 0.5; }
  .onelight-btn-apply:hover {
    cursor: pointer;
    opacity: 0.8; }

.onelight-delete-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.41px;
  color: rgba(253, 253, 255, 0.91);
  background-color: #ff471a;
  width: 160px;
  height: 36px; }
  .onelight-delete-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-delete-btn:focus {
    outline: none; }
  .onelight-delete-btn:disabled {
    opacity: 0.5; }

.onelight-delete-btn:hover {
  background-color: #ffb5a3;
  cursor: pointer; }

.onelight-second-delete-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.41px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  color: #FF3232;
  opacity: 0.6; }
  .onelight-second-delete-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-second-delete-btn:focus {
    outline: none; }
  .onelight-second-delete-btn:disabled {
    opacity: 0.5; }
  .onelight-second-delete-btn:hover {
    cursor: pointer;
    opacity: 0.8;
    background-color: rgba(255, 255, 255, 0); }

.onelight-second-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: -0.41px;
  color: #727578; }
  .onelight-second-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-second-btn:focus {
    outline: none; }
  .onelight-second-btn:disabled {
    opacity: 0.5; }
  .onelight-second-btn:hover {
    cursor: pointer;
    color: #5f5f5f;
    background-color: rgba(255, 255, 255, 0); }

.onelight-second-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(255, 255, 255, 0);
  font-size: 16px;
  line-height: 18px;
  color: #727578;
  letter-spacing: -0.41px; }
  .onelight-second-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .onelight-second-btn:focus {
    outline: none; }
  .onelight-second-btn:disabled {
    opacity: 0.5; }
  .onelight-second-btn:hover {
    cursor: pointer;
    color: #5f5f5f;
    background-color: rgba(255, 255, 255, 0); }

.onelight-move-btn {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 28px;
  letter-spacing: -0.41px;
  color: #000000;
  border-radius: 7px; }
  .onelight-move-btn:hover {
    cursor: pointer;
    opacity: 0.8; }

.onelight-input, .onelight-input-search {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  place-content: center;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 30px;
  background: #FFFFFF;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  color: #212529; }

.onelight-input:disabled, .onelight-input-search:disabled {
  background: #acacac78; }

.onelight-input::placeholder, .onelight-input-search::placeholder {
  color: #727578;
  font-size: 14px;
  line-height: 30px; }

.onelight-input:focus, .onelight-input-search:focus {
  border: 1px solid #00AEAE;
  outline: none; }

.onelight-input-search {
  border: 1px solid #CED4DA;
  border-radius: 26px;
  padding-left: 30px;
  background: url(../imgs/search-icon.svg) no-repeat scroll 14px 10px; }

.onelight-input-search::placeholder {
  color: #999999;
  opacity: 1; }

.onelight-input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

.onelight-input-file-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  height: 30px;
  border: 1px solid #E4EAF0;
  border-radius: 4px;
  position: relative;
  justify-content: space-between;
  align-items: center; }

.onelight-input-file-container:hover {
  cursor: pointer;
  opacity: 0.8; }

.onelight-input-file-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  letter-spacing: -0.15px;
  color: #6C7177;
  padding-left: 7px; }

.onelight-input-file-btn {
  background: #F2F2F2;
  border-radius: 3px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 9px;
  letter-spacing: -0.15px;
  min-width: 61px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 7px; }

.onelight-badge-container {
  display: flex;
  flex-wrap: wrap; }

.onelight-badge, .onelight-badge-active {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  max-width: 120px;
  height: 34px;
  border: 1px solid #E4EAF0;
  border-radius: 4px; }
  .onelight-badge-active {
    background: rgba(0, 218, 218, 0.3);
    border: 1px solid #00DADA; }
  .onelight-badge:hover, .onelight-badge-active:hover {
    cursor: pointer;
    border: 1px solid #00DADA;
    background: rgba(0, 218, 218, 0.5); }

.onelight-badge-text, .onelight-badge-text-active {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  margin: auto;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  color: #7F8489;
  letter-spacing: -0.15px;
  padding-left: 12px;
  padding-right: 12px; }
  .onelight-badge-text-active {
    color: #212529; }

.onelight-head-text, .whats-new-list-item-col, .whats-new-list-item-date-col, .whats-new-list-item-date-col-header, .whats-new-list-item-col-header, .aso-experiment-list-item-col, .aso-experiment-list-item-date-col, .aso-experiment-list-item-col-header, .promo-list-item-date-col, .promo-list-item-col-header, .promo-list-item-col {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.onelight-second-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: rgba(108, 113, 119, 0.87); }

.onelight-switcher-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #212529; }

.onelight-attention-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 20px;
  line-height: 18px;
  text-transform: uppercase;
  color: #232326; }

.onelight-attention-description-text, .onelight-attention-description-text-br {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #727578;
  text-align: center; }
  .onelight-attention-description-text-br {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.head-panel {
  height: 100%;
  display: flex;
  min-width: 245px;
  background-color: black;
  flex-direction: column;
  align-items: flex-start; }

.head-panel-separator {
  width: 100%;
  height: 1px;
  background-color: #E4EAF0;
  opacity: 0.2;
  margin-top: 13px;
  margin-bottom: 24px; }

.head-item, .head-panel-subtitle, .head-item-link, .head-item-link-active {
  margin-left: 50px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.head-panel-subtitle {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #E4EAF0;
  opacity: 0.6;
  padding-bottom: 11px; }

.head-item-link, .head-item-link-active {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-bottom: 14px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #F2F5F8;
  z-index: 1; }
  .head-item-link-active {
    color: #00CCCC;
    fill: #00CCCC; }

.head-item-link:hover, .head-item-link-active:hover {
  color: #00AEAE;
  fill: #00AEAE; }

.modal {
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  transition: 1.1s ease-out;
  transform: scale(1);
  visibility: visible;
  position: fixed;
  z-index: 1000; }

.modal-content {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1001;
  max-height: 100vh; }

.body-container {
  display: flex;
  width: 100%;
  min-height: 0px; }

.def-page-container, .metrics-container, .ranks-container, .aso-container, .aso-experiment-body, .aso-report-container, .promo-container, .qa-container {
  padding: 70px 80px 0px 30px; }

.attention-container {
  background-color: white;
  border-radius: 8.01966px;
  padding-right: 25px;
  width: 512px; }

.attention-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px; }

.attention-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 70px;
  padding-bottom: 20px; }

.save-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  text-align: center;
  letter-spacing: -0.2px; }
  .save-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .save-btn:focus {
    outline: none; }
  .save-btn:disabled {
    opacity: 0.5; }

.add-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  height: 31px;
  text-align: center;
  letter-spacing: -0.2px; }
  .add-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .add-btn:focus {
    outline: none; }
  .add-btn:disabled {
    opacity: 0.5; }

.onelight-dropdown-wrapper {
  display: flex;
  position: relative; }

.onelight-dropdown {
  visibility: visible;
  position: absolute;
  z-index: 999;
  top: 100%; }

.onelight-input-select-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 30px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #FFFFFF;
  padding-left: 8px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  color: #212529; }
  .onelight-input-select-container:hover {
    cursor: pointer; }

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px; }

.details-body {
  display: flex;
  padding-top: 30px;
  flex-direction: column; }

.details-container {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 468px;
  padding-left: 24px;
  padding-right: 24px; }

.details-footer {
  display: flex;
  padding-top: 50px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 17px; }

.chart-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  margin-top: 21px;
  padding-left: 6px;
  background: white;
  border-radius: 5px;
  min-width: 45%; }

.metrics-dashboard-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.expand-icon {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  height: 25px;
  width: 25px; }

.expand-icon:hover {
  opacity: 0.7;
  cursor: pointer; }

.metrics-container {
  overflow-y: auto;
  width: 100%; }

.metrics-header-container {
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 0px 6px 6px 6px;
  width: 100%;
  padding: 6px 0px 6px 0px; }

.metrics-header-tab, .metrics-header-tab-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  height: 40px;
  width: 120px;
  box-shadow: 1px -1px 5px rgba(128, 134, 149, 0.2);
  border-radius: 6px 6px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.41px;
  color: #727578;
  background-color: #E4EAF0;
  margin-right: 2px; }
  .metrics-header-tab:hover, .metrics-header-tab-active:hover {
    cursor: pointer;
    opacity: 0.8; }
  .metrics-header-tab-active {
    color: #232326;
    background: #FFFFFF; }

.react-datepicker-popper {
  z-index: 3; }

.app-select-label-text {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 10px;
  line-height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.days-picker-container {
  display: flex;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.days-label-picker {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.days-label-picker:hover {
  cursor: pointer;
  opacity: 0.7; }

.line-separator-charts {
  width: 100%;
  border-top: 1px dashed rgba(157, 157, 157, 0.52); }

.charts-selector-container {
  display: flex;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.charts-selector-label {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.charts-selector-label:hover {
  cursor: pointer;
  opacity: 0.7; }

.legends-container {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.legend-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(2px 5px 10px rgba(35, 35, 38, 0.1)); }

.legend-header {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  color: #232326;
  font-size: 18px;
  line-height: 18px;
  height: 45px;
  margin-left: 6px;
  border-bottom: 1px solid #E4EAF0;
  display: flex;
  align-items: center; }

.legend-description {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  color: #232326;
  font-size: 14px;
  line-height: 18px;
  height: 35px;
  margin-left: 6px;
  letter-spacing: -0.41px;
  display: flex;
  align-items: center; }

.select-app-label {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-align: center;
  padding-left: 14px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.390867px;
  color: #232326; }

.select-label {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  text-align: center; }

.add-event-container {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  align-self: flex-end;
  font-size: 18px;
  line-height: 18px;
  color: #232326;
  padding-top: 21px;
  padding-right: 15px; }

.add-event-container:hover {
  cursor: pointer;
  opacity: 0.7; }

.event-tooltip-date {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 12px;
  color: #232326; }

.event-tooltip-name {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  max-width: 20px;
  font-size: 12px; }

.date-input, .date-input-disabled {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 15px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #E4EAF0;
  background-color: white;
  padding: 5px 6px 6px 5px; }

.date-input-disabled {
  opacity: 0.2; }

.event-display-text {
  padding-top: 5px;
  align-self: center; }

.copy-to-buffer-btn {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.copy-to-buffer-btn:hover {
  opacity: 0.6;
  cursor: pointer; }

.scroll-up-button {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  position: absolute;
  bottom: 50px;
  right: 15px;
  width: 50px;
  height: 50px; }

.scroll-up-button:hover {
  cursor: pointer;
  opacity: 0.6; }

.period-selector-container {
  display: flex; }

.period-label {
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-right: 5px; }

.incent-app-units-container {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 13px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px; }

.multi-select-label {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326; }

.react-datepicker {
  border: none; }

.react-datepicker__header {
  background-color: white;
  padding-top: 24px;
  border: none; }

.react-datepicker__day-names {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 13px;
  line-height: 24px;
  color: #232326; }

.react-datepicker__month-container {
  width: 344px;
  height: 360px; }

.react-datepicker__day-name {
  padding: 8px;
  margin: 0px; }

.react-datepicker__day {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 13px;
  padding: 8px;
  margin: 0px;
  color: #232326; }
  .react-datepicker__day:hover {
    cursor: pointer;
    background: rgba(0, 204, 204, 0.1);
    outline: none; }
  .react-datepicker__day--selected {
    background-color: #00CCCC;
    color: white;
    outline: none; }
    .react-datepicker__day--selected:hover {
      background-color: #00CCCC; }

.react-datepicker__day--outside-month {
  color: #cccccc;
  pointer-events: none; }

.calendar-single-input {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  height: 32px;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  color: #232326;
  padding-right: 9px; }
  .calendar-single-input:hover {
    cursor: pointer;
    opacity: 0.7; }

.calendar-from-to-input {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  width: 215px;
  height: 32px;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  color: #232326;
  margin-bottom: 3px; }
  .calendar-from-to-input:hover {
    cursor: pointer;
    opacity: 0.7; }

.calendar-from-to {
  filter: drop-shadow(2px 8px 30px rgba(35, 35, 38, 0.2));
  display: flex;
  flex-direction: column;
  background-color: white; }

.switcher-label-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #232326;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.41px; }

.update-time-header {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: -0.1px;
  color: #A3A5A7;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 22px; }

.add-app-container {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  width: 80px;
  height: 24px; }
  .add-app-container:hover {
    cursor: pointer;
    opacity: 0.8; }

.add-metadata-container {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  width: 145px;
  height: 24px; }
  .add-metadata-container:hover {
    cursor: pointer;
    opacity: 0.8; }

.onelight-date-picker {
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3)); }

.img-edit-dnd-container, .img-edit-dnd-container-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 1px solid #E4EAF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.4px;
  position: relative; }

.img-edit-dnd-container-active {
  border: 1px solid #14FFF1; }

.img-edit-dnd-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.165517px;
  color: #6C7177;
  padding-top: 13px; }

.img-edit-container {
  position: relative;
  border-radius: 4.40678px;
  display: flex;
  border: 1px solid rgba(242, 249, 255, 0); }

.img-edit {
  margin: auto;
  object-fit: cover;
  border: 1px solid #E4EAF0;
  border-radius: 4.40678px; }
  .img-edit:focus {
    outline: none; }

.close-img {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 10px; }

.close-img:hover {
  opacity: 0.8;
  cursor: pointer; }

.whats-new-list-header {
  display: flex;
  padding: 10px 0px 10px 0px;
  flex-shrink: 0; }

.whats-new-list-item {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  padding: 14px 0px 14px 0px;
  flex-shrink: 0;
  margin-top: 5px;
  border-top: 2px solid #E4EAF0; }
  .whats-new-list-item:hover {
    cursor: pointer;
    opacity: 0.7; }

.whats-new-list-item-col, .whats-new-list-item-date-col, .whats-new-list-item-date-col-header, .whats-new-list-item-col-header {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis; }

.whats-new-list-item-date-col, .whats-new-list-item-date-col-header {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #232326;
  font-weight: 400;
  padding-right: 21px;
  width: 120px;
  margin-left: auto; }

.whats-new-list-item-col-header, .whats-new-list-item-date-col-header {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  color: #232326; }

.whats-new-list-item-date-col-header {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  color: #232326; }

.whats-new-head-panel {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 14px 0px 14px;
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3));
  margin-bottom: 10px; }

.add-whats-new-btn {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  width: 140px;
  height: 36px;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #232326;
  padding: 0px;
  border-radius: 4px; }

.publish-btn {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  width: 100px;
  height: 30px;
  font-size: 16px;
  line-height: 18px;
  color: #232326;
  padding: 0px;
  border-radius: 8px; }

.pointer-element:hover {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 0.8;
  cursor: pointer; }

.basic-multi-select {
  min-width: 180px; }

.store-selector-icon, .store-selector-icon-active, .store-selector-icon-unactive {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.store-selector-icon:hover, .store-selector-icon-active:hover, .store-selector-icon-unactive:hover {
  cursor: pointer;
  opacity: 0.8; }

.store-selector-icon-active {
  color: black; }

.store-selector-icon-unactive {
  color: #8a8a8a; }

.cross-promo-head-panel {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 14px 0px 14px;
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3));
  margin-bottom: 10px; }

.drag-dots {
  width: 12px;
  color: #999999;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  padding-right: 10px; }

.drag-dots:after {
  content: '\283F';
  font-size: 25px; }

.img-edit-dnd-container, .img-edit-dnd-container-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 1px solid #E4EAF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.4px;
  position: relative; }

.img-edit-dnd-container-active {
  border: 1px solid #14FFF1; }

.img-edit-dnd-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.165517px;
  color: #6C7177;
  padding-top: 13px; }

.img-edit-container {
  position: relative;
  border-radius: 4.40678px;
  display: flex;
  border: 1px solid rgba(242, 249, 255, 0); }

.img-edit {
  margin: auto;
  object-fit: cover;
  border: 1px solid #E4EAF0;
  border-radius: 4.40678px; }
  .img-edit:focus {
    outline: none; }

.close-img {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 10px; }

.close-img:hover {
  opacity: 0.8;
  cursor: pointer; }

.close {
  position: absolute; }

.close:hover {
  opacity: 0.3; }

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #8e8e8e; }

.close:before {
  transform: rotate(45deg); }

.close:after {
  transform: rotate(-45deg); }

.store-selector-icon, .store-selector-icon-active, .store-selector-icon-unactive {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear; }

.store-selector-icon:hover, .store-selector-icon-active:hover, .store-selector-icon-unactive:hover {
  cursor: pointer;
  opacity: 0.8; }

.store-selector-icon-active {
  color: black; }

.store-selector-icon-unactive {
  color: #8a8a8a; }

.cross-promo-head-panel {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 14px 0px 14px;
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3));
  margin-bottom: 10px; }

.drag-dots {
  width: 12px;
  color: #999999;
  -webkit-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  padding-right: 10px; }

.drag-dots:after {
  content: '\283F';
  font-size: 25px; }

.img-edit-dnd-container, .img-edit-dnd-container-active {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 1px solid #E4EAF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.4px;
  position: relative; }

.img-edit-dnd-container-active {
  border: 1px solid #14FFF1; }

.img-edit-dnd-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.165517px;
  color: #6C7177;
  padding-top: 13px; }

.img-edit-container {
  position: relative;
  border-radius: 4.40678px;
  display: flex;
  border: 1px solid rgba(242, 249, 255, 0); }

.img-edit {
  margin: auto;
  object-fit: cover;
  border: 1px solid #E4EAF0;
  border-radius: 4.40678px; }
  .img-edit:focus {
    outline: none; }

.close-img {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 10px; }

.close-img:hover {
  opacity: 0.8;
  cursor: pointer; }

.login-container {
  width: 26vw;
  margin: 27vh auto;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center; }

.login-logo-div {
  padding-top: 47px;
  display: flex;
  align-items: center; }

.login-logo {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 3px solid #000000; }

.login-logo-text {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: 0.01em;
  padding-left: 9px;
  font-size: 13px;
  line-height: 20px;
  color: #000000; }

.login-input-div {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column; }

.login-input {
  width: 16vw;
  height: 30px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 17px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #DBDBDB;
  margin-top: 40px; }

.login-input:focus {
  outline: none;
  border-bottom: 1px solid #00AEAE; }

.login-input::placeholder {
  color: #A7A8AA;
  font-size: 14px;
  line-height: 17px; }

.login-btn {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  background: #00CCCC;
  border-radius: 40px;
  font-size: 12px;
  padding: 4px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #232326;
  letter-spacing: -0.41px;
  width: 16vw;
  height: 40px;
  margin-bottom: 45px;
  margin-top: 35px;
  text-transform: uppercase; }
  .login-btn:hover {
    cursor: pointer;
    background: #00AEAE; }
  .login-btn:focus {
    outline: none; }
  .login-btn:disabled {
    opacity: 0.5; }

.login-btn:disabled {
  color: #a0cbd2;
  background-color: #00dada69; }

.err-msg {
  font-size: 13px;
  padding-top: 50px;
  color: #f75130;
  width: 70%;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-align: center; }

@media screen and (max-width: 1024px) {
  .login-container {
    width: 50vw;
    margin: 35vh auto; }
  .login-input {
    width: 36vw; }
  .login-btn {
    width: 36vw; }
  .login-logo-div {
    padding-top: 40px; } }

@media screen and (max-width: 800px) {
  .login-container {
    width: 50vw;
    margin: 35vh auto; }
  .login-input {
    width: 36vw; }
  .login-btn {
    width: 36vw; }
  .login-logo-div {
    padding-top: 40px; } }

@media screen and (max-width: 450px) {
  .login-container {
    width: 80vw;
    margin: 12vh auto; }
  .login-input {
    width: 60vw; }
  .login-btn {
    width: 60vw; }
  .login-logo-div {
    padding-top: 40px; } }

@media screen and (max-height: 450px) {
  .login-div {
    margin: 4vh auto; } }

@media screen and (max-height: 400px) {
  .login-container {
    width: 80vw;
    margin: 2vh auto; }
  .login-input {
    width: 60vw; }
  .login-input-div {
    padding-bottom: 8px; }
  .login-btn {
    width: 60vw; }
  .login-logo-div {
    padding-top: 20px; } }

.ranks-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0; }

.ranks-header {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding-top: 26px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  flex-shrink: 0; }

.rank-list-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-top: 14px;
  padding-left: 18px; }

.ranks-body {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 8px;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  margin-bottom: 20px; }

.rank-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 18px; }

.rank {
  display: flex; }

.rank-list-header-app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  width: 225px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  font-size: 18px;
  line-height: 18px;
  color: #232326; }

.rank-list-value-app {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  width: 225px;
  display: flex;
  align-items: center;
  margin-right: 10px; }

.rank-list-value-app-name {
  word-wrap: break-word;
  text-align: center; }
  .rank-list-value-app-name:hover {
    opacity: 0.75; }

.rank-list-header-country {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #E4EAF0;
  text-transform: uppercase;
  width: 57px;
  height: 50px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.rank-list-value-country {
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #E4EAF0;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 57px;
  height: 45px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326; }
  .rank-list-value-country:hover {
    cursor: pointer;
    opacity: 0.8; }

.rank-list-img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px; }

.aso-container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.aso-experiments-list-container {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px;
  border-bottom: #8a8a8a solid 2px; }

.aso-experiments-list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; }

.aso-experiment-list-item {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  padding: 14px 23px 14px 23px;
  flex-shrink: 0;
  margin-top: 5px;
  border-top: 2px solid #E4EAF0; }
  .aso-experiment-list-item:hover {
    cursor: pointer;
    opacity: 0.7; }

.aso-experiment-list-item-col, .aso-experiment-list-item-date-col, .aso-experiment-list-item-col-header, .promo-list-item-date-col, .promo-list-item-col-header {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis; }

.aso-experiment-list-item-date-col {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #232326;
  font-weight: 400; }

.aso-experiment-list-item-col-header {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  color: #232326; }

.aso-experiment-list-item-btns-col {
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.aso-experiment-list-header {
  display: flex;
  padding: 10px 23px 10px 23px;
  flex-shrink: 0; }

.aso-experiment-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0; }

.aso-experiment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  min-height: 50px;
  border-radius: 6px;
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3)); }

.aso-experiment-phrases-list-container, .aso-experiment-phrases-ranks-positions-list-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
  background-color: white;
  border-radius: 8px 0px 0px 8px; }

.aso-experiment-phrases-ranks-positions-list-container {
  width: 40%; }

.aso-experiment-phrases-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 1;
  width: 100%;
  height: 100%; }

.aso-experiment-keywords-list-container {
  display: flex;
  flex-direction: column;
  margin-right: 34px;
  width: 50%;
  height: 100%;
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4)); }

.aso-experiment-keywords-list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%; }

.aso-experiment-forms-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(2px 4px 6px rgba(184, 184, 184, 0.3)); }

.aso-experiment-forms-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px 20px 16px; }

.keyword {
  display: flex;
  background-color: white;
  flex-shrink: 0;
  height: 40px; }

.keywords-list-header {
  display: flex;
  align-items: center;
  margin-top: 6px;
  flex-shrink: 0; }

.phrase-list-header-item-container {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #232326;
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 4px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  width: 100%;
  border-bottom: 2px solid #E4EAF0; }

.keyword-list-header-checkbox {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #232326;
  height: 30px;
  border-bottom: 2px solid #E4EAF0;
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0; }

.keyword-list-value-checkbox {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  border-bottom: 2px solid #E4EAF0;
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0; }

.keyword-list-header-word {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #232326;
  height: 30px;
  border-bottom: 2px solid #E4EAF0;
  width: 34%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0; }

.keyword-list-value-word {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  border-bottom: 2px solid #E4EAF0;
  width: 34%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0; }

.keyword-list-header-count {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #232326;
  height: 30px;
  border-bottom: 2px solid #E4EAF0;
  width: 15%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0; }

.keyword-list-value-count {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  border-bottom: 2px solid #E4EAF0;
  width: 15%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #E4EAF0;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.keyword-list-header-length {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #232326;
  height: 30px;
  border-bottom: 2px solid #E4EAF0;
  width: 15%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.keyword-list-value-length {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  border-bottom: 2px solid #E4EAF0;
  width: 15%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.aso-input-with-limit-container {
  display: flex;
  align-items: center;
  margin-right: 15px; }

.aso-input-with-limit {
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #727578;
  width: 200px; }

.aso-input-limit {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 10px;
  line-height: 22px;
  /* identical to box height, or 220% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1px;
  /* Gray Disabled */
  margin-right: 4px;
  color: #A3A5A7;
  width: 40px; }

.aso-form-keywords-container {
  width: 25%;
  word-wrap: break-word;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid #E4EAF0;
  min-height: 34px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: -0.15px;
  font-size: 15px;
  line-height: 17px; }

.aso-form-notes-container {
  width: 15%;
  word-wrap: break-word;
  padding: 3px;
  border-radius: 4px;
  min-height: 34px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  letter-spacing: -0.15px;
  font-size: 15px;
  line-height: 17px;
  padding-right: 10px; }

.aso-overlapping-container {
  color: #E5568A;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.41px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-left: 16px; }

.aso-forms-buttons-container {
  display: flex;
  align-items: center; }

.aso-keywords-list-header-arrow-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 5px; }
  .aso-keywords-list-header-arrow-container:hover {
    opacity: 0.8;
    cursor: pointer; }

.aso-experiment-list-item-icon {
  padding-right: 15px; }
  .aso-experiment-list-item-icon:hover {
    cursor: pointer;
    opacity: 0.8; }

.aso-report-data-table {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px; }

.aso-report-row {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  font-size: 16px; }

.aso-report-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column; }

.aso-report-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-top: 10px; }

.aso-report-item-header {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  line-height: 18px;
  color: #232326;
  padding-top: 10px; }

.aso-report-header {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: #232326;
  border-bottom: 2px solid #E4EAF0;
  padding-bottom: 4px; }

.aso-report-row {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  height: 45px;
  display: flex;
  border-bottom: 1px solid #E4EAF0; }

.aso-report-value, .aso-report-date, .aso-report-day, .aso-report-impressions, .aso-report-pageviews, .aso-report-appunits, .aso-report-ratio {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: #E4EAF0 solid 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #232326; }

.aso-report-date {
  width: 150px; }

.aso-report-day {
  width: 90px; }

.aso-report-impressions {
  width: 120px; }

.aso-report-pageviews {
  width: 130px; }

.aso-report-appunits {
  width: 130px; }

.aso-report-ratio {
  width: 70px; }

.aso-report-charts-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
  overflow-y: auto;
  width: 30%; }

.aso-report-charts {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px; }

.aso-report-charts-pie {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #232326;
  font-size: 18px;
  line-height: 18px;
  height: 42px;
  display: flex;
  margin: auto;
  align-items: center; }

.aso-report-charts-pie-title {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #232326; }

.aso-report-charts-pie-head-title {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.41px;
  color: #A3A5A7;
  padding-top: 7px; }

.chart-pie-country-label {
  padding: 20px;
  align-self: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px; }

.chart-pie-country-label-region-text {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #727578; }

.aso-report-country-legend-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap; }

.aso-report-country-legend {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #232326;
  padding: 5px;
  display: flex;
  align-items: center; }

.aso-report-country-legend-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block; }

.aso-report-xlsx-btn {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 15px; }

.aso-report-xlsx-btn:hover {
  cursor: pointer;
  opacity: 0.7; }

.aso-experiment-forms-list-item-name {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  letter-spacing: -0.41px;
  color: #232326;
  font-size: 16px;
  line-height: 18px;
  width: 108px; }

.search-keyword-input {
  background: #FFFFFF;
  padding-left: 6px;
  min-height: 32px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: #727578;
  border: none;
  filter: drop-shadow(1px 3px 4px rgba(184, 184, 184, 0.3)); }

.search-keyword-input::placeholder {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #727578; }

.search-keyword-input:focus {
  outline: none; }

.aso-report-release-label {
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #A3A5A7; }

.keywords-tables-container {
  display: flex;
  flex-wrap: wrap; }

.keywords-country-container {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 4px;
  margin: 8px;
  border-radius: 7px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 13px -15px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -15px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0); }

.keywords-country-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  align-self: center;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  padding-top: 15px;
  padding-bottom: 10px;
  width: 92%; }

.keywords-country-table-header {
  display: flex;
  padding-top: 15px;
  align-items: center;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  border-bottom: 1px solid rgba(0, 0, 0, 0.46); }

.keywords-country-table-header-kws, .keyword-rank-value {
  flex: 5;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: inherit;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  height: 100%; }

.keywords-country-table-header-rank, .keyword-rank-position {
  flex: 2;
  display: flex;
  align-items: inherit;
  padding-left: 6px;
  border-left: 1px solid #E4EAF0;
  height: 100%;
  min-width: 75px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.keywords-country-table-header-status, .keyword-rank-status {
  flex: 3;
  display: flex;
  align-items: inherit;
  padding-left: 6px;
  border-left: 1px solid #E4EAF0;
  height: 100%;
  max-width: 80px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.keywords-country-table-header-notes, .keyword-rank-notes {
  flex: 7;
  padding-left: 10px;
  height: 100%;
  border-left: 1px solid #E4EAF0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.keywords-country-table-header-history, .keyword-rank-history {
  flex: 7;
  padding-left: 10px;
  height: 100%;
  border-left: 1px solid #E4EAF0;
  text-align: center;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.keywords-country-table-list {
  max-height: 400px;
  overflow-y: auto; }

.keyword-rank-container {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  height: 35px;
  position: relative;
  display: flex;
  font-size: 19px;
  align-items: center;
  border-bottom: 1px solid #E4EAF0; }

.keyword-rank-value {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #5a5757;
  height: 100%;
  position: relative; }

.keyword-rank-value:hover {
  /*cursor: pointer;
  z-index: 1000;
  overflow: visible;
  background-color: white;
  position: absolute;*/ }

.rank-container {
  display: flex;
  justify-content: center; }

.arrow-rank {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 5px; }

.up-rank {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-color: #34C759; }

.down-rank {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-color: #FF3232; }

.keyword-rank-position-diff, .keyword-rank-position-diff-up, .keyword-rank-position-diff-down {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 5px; }

.keyword-rank-position-diff-up {
  color: #34C759; }

.keyword-rank-position-diff-down {
  color: #FF3232; }

.add-keyword-button {
  font-size: 15px;
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"; }

.add-keyword-button:hover {
  cursor: pointer;
  opacity: 0.6; }

.keywords-details-switcher-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px; }

.keywords-details-switcher-text {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 16px; }

.positions-incent-keywords-header-container {
  display: flex;
  align-items: center;
  width: 100%; }

.keywords-switcher, .keywords-switcher-active {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 5px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-transform: uppercase;
  border-radius: 5px 5px 0px 0px;
  color: #8e8e8e; }

.keywords-switcher-active {
  background-color: white;
  color: rgba(0, 218, 218, 0.74); }

.keywords-switcher:hover, .keywords-switcher-active:hover {
  cursor: pointer; }

.keyword-rank-settings {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  align-items: center;
  position: absolute;
  right: -5px;
  height: 100%;
  background-color: white; }

.keyword-rank-settings-icon {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding-left: 10px;
  max-width: 13px;
  max-height: 15px; }

.keyword-rank-settings-icon:hover {
  cursor: pointer;
  opacity: 0.8; }

.keyword-status-input, .keyword-status-input-select {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 3px; }
  .keyword-status-input:hover, .keyword-status-input-select:hover {
    opacity: 0.8;
    cursor: pointer; }
  .keyword-status-input-select {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E4EAF0;
    border-radius: 4px; }

.keyword-status {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.41px;
  color: #232326;
  width: 59px;
  height: 30px;
  border-radius: 4px;
  padding-left: 6px; }
  .keyword-status:hover {
    opacity: 0.8;
    cursor: pointer; }

.keyword-status-list {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3px;
  filter: drop-shadow(2px 8px 30px rgba(35, 35, 38, 0.2)); }

.empty-dates-body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3px;
  filter: drop-shadow(2px 8px 30px rgba(35, 35, 38, 0.2));
  width: 130px;
  min-height: 30px; }

.empty-dates-icon {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding-right: 20px;
  display: flex;
  margin: auto; }

.empty-dates-icon:hover {
  opacity: 0.8;
  cursor: pointer; }

.promo-container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.promo-list-container {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px; }

.promo-list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; }

.promo-list-item {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  display: flex;
  padding: 14px 23px 14px 23px;
  flex-shrink: 0;
  margin-top: 5px;
  border-top: 2px solid #E4EAF0; }
  .promo-list-item:hover {
    cursor: pointer;
    opacity: 0.7; }

.promo-list-item-col {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212529;
  letter-spacing: -0.15px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis; }

.promo-list-item-date-col {
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #232326;
  font-weight: 400; }

.promo-list-item-col-header {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  line-height: 18px;
  color: #232326; }

.promo-list-item-btns-col {
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.promo-list-header {
  display: flex;
  padding: 10px 23px 10px 23px;
  flex-shrink: 0; }

.cross-promo-list-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px;
  padding: 20px 0px;
  width: 300px; }

.cross-promo-list-item, .cross-promo-list-item-selected {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 5px;
  color: #232326;
  border: 1px solid rgba(255, 255, 255, 0);
  border-top: 1px solid #F0F0F0;
  background-color: white; }

.cross-promo-list-item:hover, .cross-promo-list-item-selected:hover {
  cursor: pointer;
  border-top: 1px solid #00DADA;
  border-bottom: 1px solid #00DADA; }

.cross-promo-list-item-selected {
  border-top: 1px solid #00DADA;
  border-bottom: 1px solid #00DADA; }

.cross-promo-editor-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px;
  padding: 20px 10px;
  width: 500px;
  height: max-content;
  margin-left: 50px; }

.cross-promo-preview-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column-reverse;
  overflow: auto;
  background-color: white;
  background-size: cover !important;
  background-position: center !important;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px;
  padding: 20px 10px;
  width: 390px;
  height: 750px; }

.cross-promo-editor-locales-container {
  display: flex;
  flex-shrink: 0;
  overflow: auto;
  background-color: white;
  filter: drop-shadow(2px 6px 20px rgba(184, 184, 184, 0.4));
  border-radius: 6px;
  padding: 20px 10px;
  width: 800px; }

.cross-promo-btn {
  -webkit-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center; }

.cross-promo-btn:hover {
  cursor: pointer;
  opacity: 0.8; }

.cross-promo-editor-locales-item {
  display: flex;
  flex-direction: column;
  padding-right: 15px; }

.qa-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%; }

.debug-header {
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  display: flex; }

.debug-section-title {
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 22px; }

.debug-info-container {
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 15px; }
